<template>
  <div>
    <h2 v-html="$t('search_dairy_title')"></h2>
    <!-- MBH-Ident -->
    <v-text-field
      id="search_dairy_ident"
      :rules="[$rules.isNumericList && rules.required]"
      v-model="searchCardStore.Dairy_ident"
      :label="$t('search_dairy_ident')"
      type="text"
      @keyup.enter="search"
    />
    <!-- BUR -->
    <v-text-field id="search_dairy_berno" v-model="searchCardStore.Dairy_berNo" :label="$t('search_dairy_berno')" type="text" @keyup.enter="search" />
    <!-- Betriebsform -->
    <v-select
      id="search_dairy_dairytypeid"
      v-model="searchCardStore.Dairy_dairyTypeId"
      :label="$t('search_dairy_dairytypeid')"
      :items="dairyTypesIncVirtual"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />

    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- iban -->
      <v-text-field
        id="search_dairy_iban"
        v-model="searchCardStore.Dairy_iban"
        :label="$t('search_dairy_iban')"
        type="text"
        @keyup.enter="search"
        v-if="$privileges.has({ path: '/dairies', permission: 'searchProtected' })"
      />
      <!-- BLW Kreditoren-Nr. -->
      <v-text-field
        id="search_dairy_sapno"
        v-model="searchCardStore.Dairy_sapNo"
        :label="$t('search_dairy_sapno')"
        type="number"
        @keyup.enter="search"
        v-if="$privileges.has({ path: '/dairies', permission: 'searchProtected' })"
      />
      <!-- Zulassungs-Nr -->
      <v-text-field
        id="search_dairy_permitno"
        v-model="searchCardStore.Dairy_permitNo"
        :label="$t('search_dairy_permitno')"
        type="text"
        @keyup.enter="search"
      />
      <!-- Zuständiger Mitarbeiter -->
      <v-select
        id="search_dairy_personid"
        v-if="$privileges.has({ path: '/dairies', permission: 'allDomains' })"
        v-model="searchCardStore.Dairy_responsibilityGroup"
        :label="$t('search_dairy_personid')"
        :items="responsibilityGroups"
        :item-title="this.$getLangKey()"
        item-value="name"
        clearable
        @keyup.enter="search"
      />
      <!-- TSM Stelle -->
      <v-select
        id="search_dairy_tsmoffice"
        v-if="$privileges.has({ path: '/tsmOffices', permission: 'read' })"
        v-model="searchCardStore.Dairy_tsmOffice"
        :label="$t('search_dairy_tsmoffice')"
        :items="workingPlaces"
        :item-title="this.$getLangKey()"
        item-value="name"
        clearable
        @keyup.enter="search"
      />
      <!-- Status -->
      <v-select
        id="search_dairy_status"
        v-model="searchCardStore.Dairy_status"
        :label="$t('search_dairy_status')"
        :items="search_dairy_status"
        item-title="text"
        item-value="id"
        clearable
        @keyup.enter="search"
      />
      <!-- Kanton -->
      <v-select
        id="search_dairy_cantonid"
        v-model="searchCardStore.Dairy_cantonId"
        :label="$t('search_dairy_cantonid')"
        :items="cantonsIncVirtual"
        :item-title="this.$getLangKey()"
        item-value="id"
        clearable
        @keyup.enter="search"
      />
      <!-- Meldepflicht MPD1 -->
      <v-select
        id="search_dairy_reporting_obligation_MPD1"
        v-model="searchCardStore.Dairy_productionReportingObligation"
        :label="$t('search_dairy_reporting_obligation_MPD1')"
        :items="reportingObligations"
        :item-title="this.$getLangKey()"
        item-value="name"
        clearable
        @keyup.enter="search"
        v-if="$privileges.has({ path: '/dairies', permission: 'searchProtected' })"
      />
      <!-- Meldepflicht TSM1 -->
      <v-select
        id="search_dairy_reporting_obligation_TSM1"
        v-model="searchCardStore.Dairy_processingReportingObligation"
        :label="$t('search_dairy_reporting_obligation_TSM1')"
        :items="reportingObligations"
        :item-title="this.$getLangKey()"
        item-value="name"
        clearable
        @keyup.enter="search"
        v-if="$privileges.has({ path: '/dairies', permission: 'searchProtected' })"
      />
      <!-- Meldepflicht BOM -->
      <v-select
        id="search_dairy_reporting_obligation_BOM"
        v-model="searchCardStore.Dairy_segmentationReportingObligation"
        :label="$t('search_dairy_reporting_obligation_BOM')"
        :items="reportingObligations"
        :item-title="this.$getLangKey()"
        item-value="name"
        clearable
        @keyup.enter="search"
        v-if="$privileges.has({ path: '/dairies', permission: 'searchProtected' })"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import baseSearchVue from './baseSearch.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import { useCantonsStore } from '@/store/enums/CantonsStore'
import { useDairyTypesStore } from '@/store/enums/DairyTypesStore'
import { useReportingObligationsStore } from '@/store/enums/ReportingObligationsStore'
import { useResponsibilityGroupsStore } from '@/store/enums/ResponsibilityGroupsStore'

export default defineComponent({
  name: 'searchDairy',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      search_dairy_status: [
        { id: 1, text: this.$t('search_dairy_active') },
        { id: 0, text: this.$t('search_dairy_not_active') }
      ],
      rules: {
        required: (value) => this.validateMbhId(value) || this.$t('search_dairy_mbh_id_not_valid')
      }
    }
  },
  computed: {
    responsibilityGroups() {
      return useResponsibilityGroupsStore().responsibilityGroups
    },
    workingPlaces() {
      return useResponsibilityGroupsStore().workingPlaces
    },
    dairyTypesIncVirtual() {
      return useDairyTypesStore().dairyTypesIncVirtual
    },
    cantonsIncVirtual() {
      return useCantonsStore().CantonsIncVirtual
    },
    reportingObligations() {
      return useReportingObligationsStore().reportingObligations
    }
  },
  methods: {
    validateMbhId(id) {
      if (id === null) return true
      let re = /^((\d+(,\d+)?)+)?$/
      let result = re.test(id)
      return result
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchDairyStore(), true)
    useDairyTypesStore().fetchDairyTypesIncVirtual()

    if (this.$privileges.has({ path: '/tsmOffices', permission: 'read' })) {
      useResponsibilityGroupsStore().fetchResponsibilityGroups()
      useResponsibilityGroupsStore().fetchWorkingPlaces()
    }
    useCantonsStore().fetchCantonsIncVirtual()
    useReportingObligationsStore().fetchReportingObligations()
  }
})
</script>
